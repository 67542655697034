import type { ReactElement } from 'react';

import { brandConfig } from '@hubcms/brand';

import type { TextOnImageTeaserProps as ChameleonTextOnImageTeaserProps } from '../../domain/chameleon/text-on-image-teaser-props';
import { getDateTimeFormat } from '../../utils/getDateTimeFormat';
import { getIntroHidden } from '../../utils/getIntroHidden';
import { getTeaserLabelProp } from '../../utils/getTeaserLabelProp';
import { hasAlternativePremiumLogo } from '../../utils/hasAlternativePremiumLogo';

import { mapImageFormatToAspectRatio } from './mapImageFormatToAspectRatio';
import type { TextOnImageTeaserProps } from './types';

export function getChameleonProps(props: TextOnImageTeaserProps, image: ReactElement | null): ChameleonTextOnImageTeaserProps {
  const teaserLabelProp = getTeaserLabelProp(props.contentType, 'TextOnImageTeaser');

  const chameleonProps: ChameleonTextOnImageTeaserProps = {
    articleType: getArticleType(props),
    dateTime: (props.hasDateTime && !props.duration && props.dateTime) || '',
    dateTimeOptions: {
      format: getDateTimeFormat(props.isDateToday),
      locale: brandConfig.dateFnsLocale,
      timeZone: brandConfig.timeZone,
    },
    duration: props.duration,
    external: props.isExternal,
    fullHeight: props.isFullHeight,
    image,
    imageOptions: {
      aspectRatio: mapImageFormatToAspectRatio(props.imageFormat),
    },
    introduction: props.introduction,
    introductionOptions: {
      clamp: 4,
      hidden: getIntroHidden(props.hasIntro),
    },
    label: teaserLabelProp === 'label' ? props.label : undefined,
    link: props.link,
    prefix: teaserLabelProp === 'prefix' ? props.label : props.prefix,
    premium: props.isPremium || hasAlternativePremiumLogo(props.theme),
    size: props.size,
    subLabel: props.subLabel,
    title: props.title,
    titleTagName: `h${props.headerLevel}` as ChameleonTextOnImageTeaserProps['titleTagName'],
    editorialLabel: props.teaserLabel,
    editorialAnimation: props.teaserShowLiveIcon,
  };

  return chameleonProps;
}

export function getArticleType(teaserProps: TextOnImageTeaserProps): ChameleonTextOnImageTeaserProps['articleType'] {
  const isVideo = teaserProps.hasHeroVideo || ['video', 'liveVideo', 'podcast'].includes(teaserProps.contentType);
  if (isVideo) {
    return 'video';
  }
  return 'article';
}
