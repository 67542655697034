import { TextOnImageTeaser as ChameleonTextOnImageTeaser, TeaserWrapper } from '@mediahuis/chameleon-react';
import cx from 'classnames';
import { type ReactElement, type RefObject, useRef } from 'react';

import { getThemeDataAttributes } from '@hubcms/utils-theme';

import { NativeTeaserImage } from '../NativeTeaserImage';
import { TeaserLink } from '../TeaserLink';
import { TeaserVideoPreview } from '../TeaserVideoPreview';

import { getChameleonProps } from './getChameleonProps';
import styles from './text-on-image-teaser.module.scss';
import type { TextOnImageTeaserProps } from './types';
import { useCustomTeaserImage } from './useCustomTeaserImage';

const getTeaserImage = ({
  videoPreviewId,
  teaserRef,
  ...props
}: TextOnImageTeaserProps & {
  videoPreviewId?: string;
  teaserRef?: RefObject<HTMLElement>;
}): ReactElement => {
  return (
    <>
      {props.image && (
        <NativeTeaserImage
          image={props.image}
          sizes={props.imageSizes}
          format={props.imageFormat}
          isPriority={props.isImagePriority}
        />
      )}
      {videoPreviewId && teaserRef && <TeaserVideoPreview previewId={videoPreviewId} teaserRef={teaserRef} />}
    </>
  );
};

const getTeaserLink = (props: TextOnImageTeaserProps): React.ReactNode => {
  if (typeof props.link === 'string' && props.trackingData) {
    return <TeaserLink url={props.link} isExternal={props.isExternal} trackingData={props.trackingData} />;
  }
  return props.link;
};

const TextOnImageTeaser = (props: TextOnImageTeaserProps) => {
  const teaserRef = useRef<HTMLDivElement>(null);
  const teaserImage = getTeaserImage({ ...props, videoPreviewId: props.videoPreviewId, teaserRef });
  const teaserLink = getTeaserLink(props);
  const chameleonProps = getChameleonProps(props, teaserImage);
  const themeDataAttributes = props.theme ? getThemeDataAttributes(props.theme) : null;

  const customTeaserImageResult = useCustomTeaserImage(props);

  const customTeaserImage = customTeaserImageResult && (
    <TeaserWrapper
      as="article"
      link={teaserLink}
      data-article-id={props.id}
      data-list-id={props.list?.id}
      className={cx(styles.customImageWrapper, {
        [styles.mobileOnly]: customTeaserImageResult.viewport === 'mobile-only',
        [styles.desktopOnly]: customTeaserImageResult.viewport === 'desktop-only',
      })}
    >
      <NativeTeaserImage
        image={customTeaserImageResult.imageObject}
        sizes={props.imageSizes}
        format={props.imageFormat}
        isPriority={props.isImagePriority}
        className={styles.customImage}
      />
    </TeaserWrapper>
  );

  if (customTeaserImageResult?.viewport === 'always') {
    return customTeaserImage;
  }

  return (
    <>
      {customTeaserImage}
      <ChameleonTextOnImageTeaser
        ref={teaserRef}
        {...chameleonProps}
        link={teaserLink}
        data-article-id={props.id}
        data-list-id={props.list?.id}
        {...themeDataAttributes}
        className={cx({
          [styles.desktopOnly]: customTeaserImageResult?.viewport === 'mobile-only',
          [styles.mobileOnly]: customTeaserImageResult?.viewport === 'desktop-only',
        })}
      />
    </>
  );
};

export default TextOnImageTeaser;
