import type { PerViewport } from '@hubcms/domain-styling';
import type { ArticleTeaserData, TeaserImage } from '@hubcms/domain-teaser';

type CustomTeaserImageResult = {
  viewport: 'mobile-only' | 'desktop-only' | 'always';
  imageObject: PerViewport<TeaserImage>;
};

export function useCustomTeaserImage(
  teaserData: Pick<ArticleTeaserData, 'customImageDesktop' | 'customImageMobile'>,
): CustomTeaserImageResult | null {
  const hasCustomImageDesktop = Boolean(teaserData.customImageDesktop);
  const hasCustomImageMobile = Boolean(teaserData.customImageMobile);

  if (!hasCustomImageDesktop && !hasCustomImageMobile) {
    return null;
  }

  const imageObject: PerViewport<TeaserImage> = {
    xs: teaserData.customImageMobile || undefined,
    sm: teaserData.customImageMobile || undefined,
    md: teaserData.customImageDesktop || undefined,
    lg: teaserData.customImageDesktop || undefined,
    xl: teaserData.customImageDesktop || undefined,
  };

  const viewport = determineViewport(hasCustomImageDesktop, hasCustomImageMobile);

  return { imageObject, viewport };
}

function determineViewport(hasCustomImageDesktop: boolean, hasCustomImageMobile: boolean) {
  if (!hasCustomImageDesktop) {
    return 'mobile-only';
  }
  if (!hasCustomImageMobile) {
    return 'desktop-only';
  }
  return 'always';
}
